var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.comment.language &&
    _vm.wallet.defaultLanguage != _vm.comment.language &&
    !_vm.comment.isTemporary &&
    _vm.comment.language != 'und'
  )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({attrs:{"disabled":_vm.comment.translateLoading,"icon":""},on:{"click":function($event){return _vm.postStore.translateComment("root-comment", _vm.comment.id)}}},'button',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.comment.showTranslatedContent ? 'primary' : ''}},[_vm._v("mdi-google-translate")])],1)]}}],null,false,3508427434)},[(!_vm.$vuetify.breakpoint.smOnly)?_c('span',[_vm._v(_vm._s(_vm.comment.showTranslatedContent ? ("See original (" + (_vm.comment.language) + ")") : 'See translation'))]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }